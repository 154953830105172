.container :global(.react-select__control) {
  @apply h-14 rounded-xl;
}

.container :global(.react-select__indicator-separator) {
  width: 0;
}

.container :global(.react-select__indicator) {
  @apply w-10 h-10 p-0 mx-2 flex items-center justify-center;
}

.container :global(.react-select__indicator) * {
  @apply text-black-900;
}

.container :global(.react-select__value-container) {
  @apply px-4;
}

.container :global(.react-select__placeholder) {
  @apply text-black-600;
}
