@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Dazzed';
  font-weight: 400;
  src: url('./assets/fonts/Dazzed-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Dazzed';
  font-weight: 400;
  font-style: italic;
  src: url('./assets/fonts/Dazzed-RegularItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Dazzed';
  font-weight: 500;
  src: url('./assets/fonts/Dazzed-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Dazzed';
  font-weight: 600;
  src: url('./assets/fonts/Dazzed-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Dazzed';
  font-weight: 700;
  src: url('./assets/fonts/Dazzed-Bold.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'Dazzed', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* slick overwrites */
.slick-next:before,
.slick-prev:before {
  content: '';
}
.slick-next,
.slick-prev {
  color: rgb(79, 70, 229);
}
.slick-next.slick-disabled,
.slick-prev.slick-disabled {
  color: #b2b4b7;
}
.slick-dots li.slick-active button:before {
  color: #5850ec;
}
.slick-prev {
  left: 0;
  z-index: 1;
}
.slick-next {
  right: 0;
  z-index: 1;
}
